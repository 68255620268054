<template>
    <div class="topDiv">
        <div class="iconsbtn" :style="lang.lalgin+`:50px;direction:rtl;`">
            <v-btn class="exportBtn exportWats" v-b-toggle.send_general_whats style="width:110px;font-size:.5rem; margin-inline-end: 10px;"><img class="exportWats" style="width:116px;" :src="origin +`/whatsappicon.png`" /></v-btn>
            <v-btn class="exportBtn exportPrint" @click="doExport(0)" style="width:110px;font-size:.4rem;">{{lang.print}}</v-btn>
            <v-btn class="exportBtn exportExcel" @click="doExport(2)" style="width:110px;font-size:.4rem;">{{lang.export_excel}}</v-btn>
            <v-btn class="exportBtn exportPDF" @click="doExport(1)" style="width:110px;font-size:.4rem;">{{lang.print_pagexport_pdfe}}</v-btn>
        </div>
        <sendGeneralWhats ref="sendGeneralWhats" />
    </div>
</template>

<script>
import axios from 'axios'
import sendGeneralWhats from '@/components/sendGeneralWhats.vue'
export default{
    components: {sendGeneralWhats},
    data() {
        return {
            search: {},
            origin: window.location.origin
        }
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        }
    },
    created(){
        // this.getSettings();
    },
    methods: {
        logout() {
            this.router.push({path: '/logout'})
        },
        
        whatsapp(){
            const mobile = this.settings.whatsapp.substring(1);
            window.open('https://wa.me/966'+mobile,"_blank");
        },
        doExport(id){
            this.search = this.$parent.collectSearch();
            this.printMe(id)
        },
        // einit() {
        //     this.$parent.preparedata();
        // },
        printDreport(){
          let pdfFrame = document.body.appendChild(document.createElement('iframe'));
          pdfFrame.style.display = 'none';
          pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
          pdfFrame.src = '../api/exprint.php?sdate='+this.sdate+'&edate='+this.edate;
        },
        printMe(id){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type',"startExport");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[search]',JSON.stringify(this.search));
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post,
            ).then((response) =>{
                const res = response.data;
                if(id == 1){
                    window.open(this.origin + '/api/export-print.php?export=' + res.results.data.newID,'_blank')
                }
                else if(id == 2)
                {
                    window.open(this.origin + '/api/export-print.php?excel&export=' + res.results.data.newID,'_blank')
                }else{
                    let pdfFrame = document.body.appendChild(document.createElement('iframe'));
                    pdfFrame.style.display = 'none';
                    pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
                    pdfFrame.src = this.origin + '/api/export-print.php?export=' + res.results.data.newID;
                    // window.print()
                }
            });
        }
    },
}
</script>
<style>
.topDiv{
    position: relative;
}
.iconsbtn{
    position: absolute;

    top:3px;
}
.iconsbtn i{
    margin-inline-end: 5px;
    font-size: 1.5em;
    cursor: pointer;
}
.exportBtn{
    border:none !important;
    box-shadow:none !important;
}
.logoutBtn{
    border:none !important;
    box-shadow:none !important;
    border-radius: 25%;
}
.exportBtn:active, .exportBtn:hover{
    border:none !important;
    box-shadow:none !important;
}
.exportPrint,.exportPrint:hover{
    background:darkblue !important;
    color:#fff !important;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-inline-end: 5px;
}
.exportExcel,.exportExcel:hover{
    background:darkgreen !important;
    color:#fff !important;
    border-left: 2px solid #fff !important;
    border-right: 2px solid #fff !important;
    border-radius: 0;
    margin-inline-end: 5px;
}
.exportPDF,.exportPDF:hover{
    background:red !important;
    color:#fff !important;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    
}
.exportWats{
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}
</style>