import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
// import Home from '../views/Home.vue'
import Home from '../views/NewHome.vue'
import Loading from '../views/Loading.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Loading',
    component: Loading
  }, 
  {
    path: '/home',
    name: 'Home',
    meta: { transition: 'slide-left' },
    component: Home
  },
  // {
  //   path: '/home',
  //   name: 'Home',
  //   meta: { transition: 'slide-left' },
  //   component: () => import( '@/views/CustomerList.vue')
  // },
  // {
  //   path: '/home',
  //   name: 'Home',
  //   meta: { transition: 'slide-left' },
  //   component: () => import( '@/views/clientList.vue')
  // },
  // {
  //   path: '/home',
  //   name: 'Home',
  //   meta: { transition: 'slide-left' },
  //   component: () => import( '@/views/POS-RETAIL.vue')
  // },
  // {
  //   path: '/home',
  //   name: 'Home',
  //   meta: { transition: 'slide-left' },
  //   component: () => import( '@/views/clientList.vue')
  // },
  // {
  //   path: '/home',
  //   name: 'Home',
  //   meta: { transition: 'slide-left' },
  //   component: () => import( '@/views/POS.vue')
  // },
  // {
  //   path: '/home',
  //   name: 'Home',
  //   meta: { transition: 'slide-left' },
  //   component: () => import( '@/views/ORDER.vue')
  // },
  {
    path: '/settings-whatsapp',
    name: 'InvoiceList',
    component: () => import(/* webpackChunkName: "whatsapp-settings" */ '@/views/whatsapp-settings.vue')
  },
  {
    path: '/cars-cards',
    name: 'CarsCards',
    component: () => import(/* webpackChunkName: "whatsapp-settings" */ '@/views/carNewCard.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "About" */ '@/views/About.vue')
  },
  {
    path: '/invoicelist',
    name: 'InvoiceList',
    component: () => import(/* webpackChunkName: "invoicesList" */ '@/views/invoicesList.vue')
  },
  {
    path: '/WhatsSet',
    name: 'WhatsSet',
    component: () => import(/* webpackChunkName: "WhatsSet" */ '@/views/WhatsSet.vue')
  },
  {
    path: '/offers',
    name: 'offers',
    component: () => import(/* webpackChunkName: "CrmOffers" */ '@/views/CrmOffers.vue')
  },
  {
    path: '/renewal',
    name: 'renewal',
    component: () => import(/* webpackChunkName: "renewal" */ '@/views/renewal.vue')
  },
  {
    path: '/CRMCalendar',
    name: 'CRMCalendar',
    component: () => import(/* webpackChunkName: "CalendarHome" */ '@/views/CalendarHome.vue')
  },
  {
    path: '/projects',
    name: 'projects-center',
    component: () => import(/* webpackChunkName: "projectCenter" */ '@/views/projectCenter.vue')
  },
  {
    path: '/sales',
    name: 'sales-center',
    component: () => import(/* webpackChunkName: "projectCenter" */ '@/views/customerNSales.vue')
  },
  {
    path: '/supervisor-report',
    name: 'supervisor-report',
    component: () => import(/* webpackChunkName: "supervisor-report" */ '@/views/supervisor-report.vue')
  },
  {
    path: '/newCRM',
    name: 'newCRM',
    component: () => import(/* webpackChunkName: "newCRM" */ '@/views/newCRM.vue')
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import(/* webpackChunkName: "calendar" */ '@/views/calendar.vue')
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: () => import(/* webpackChunkName: "sam_tasks" */ '@/views/sam_tasks.vue')
  },
  {
    path: '/WhatsApp',
    name: 'WhatsApp',
    component: () => import(/* webpackChunkName: "WhatsApp" */ '@/views/WhatsApp.vue')
  },
  {
    path: '/bill_clients',
    name: 'billClient1',
    component: () => import(/* webpackChunkName: "billClient1" */ '@/views/billClient.vue')
  },
  {
    path: '/support',
    name: 'support',
    component: () => import(/* webpackChunkName: "sam_support" */ '@/views/sam_support.vue')
  },
  {
    path: '/whats-support',
    name: 'whatsSupport',
    component: () => import(/* webpackChunkName: "sam_whats_support" */ '@/views/customerNSupport.vue')
    // component: () => import(/* webpackChunkName: "sam_whats_support" */ '@/views/sam_support.vue')
  },
  {
    path: '/actions',
    name: 'actions',
    component: () => import(/* webpackChunkName: "sam_actions" */ '@/views/sam_actions.vue')
  },
  {
    path: '/orders',
    name: 'ORDER',
    meta: { transition: 'slide-left' },
    component: () => import(/* webpackChunkName: "ORDER" */ '@/views/ORDER.vue') 
  },
  {
    path: '/quotations',
    name: 'quotations',
    component: () => import(/* webpackChunkName: "quotations" */ '@/views/quotations.vue')
  },
  {
    path: '/updateme',
    name: 'updateme',
    component: () => import(/* webpackChunkName: "updateme" */ '@/views/updateme.vue')
  },
  {
    path: '/reports/vatreports',
    name: 'vatreports',
    component: () => import(/* webpackChunkName: "vatreports" */ '@/views/vatreports.vue')
  },
  {
    path: '/vatreports',
    name: 'vatreports2',
    component: () => import(/* webpackChunkName: "vatreports2" */ '@/views/vatreports.vue')
  },
  {
    path: '/quotations/n/1',
    name: 'viewquotations',
    component: () => import(/* webpackChunkName: "quotations1" */ '@/views/quotations.vue')
  },
  {
    path: '/quotations/i/2',
    name: 'viewquotations1',
    component: () => import(/* webpackChunkName: "quotations2" */ '@/views/quotations.vue')
  },
  {
    path: '/quotations/c/3',
    name: 'viewquotations2',
    component: () => import(/* webpackChunkName: "quotations3" */ '@/views/quotations.vue')
  },
  {
    path: '/quotations/:status',
    name: 'viewquotations3',
    component: () => import(/* webpackChunkName: "quotations" */ '@/views/quotations.vue')
  },
  {
    path: '/quotations/:status',
    name: 'viewquotations4',
    component: () => import(/* webpackChunkName: "quotationstus" */ '@/views/quotations.vue')
  },
  {
    path: '/view-quot/:id',
    name: 'viewQuotation',
    component: () => import(/* webpackChunkName: "viewQuotation" */ '@/views/viewQuotation.vue')
  },
  {
    path: '/clientList',
    name: 'clientList',
    component: () => import(/* webpackChunkName: "clientList" */ '@/views/clientLis-old.vue')
  },
  {
    path: '/clientList-mobile',
    name: 'clientList-mobile',
    component: () => import(/* webpackChunkName: "clientList-mobile" */ '@/views/clientList-mobile.vue')
  },
  {
    path: '/settings/statuses',
    name: 'statuses',
    component: () => import(/* webpackChunkName: "settings-statuses" */ '@/views/settings-statuses.vue')
  },
  {
    path: '/Project',
    name: 'Project',
    component: () => import(/* webpackChunkName: "Project" */ '@/views/Project.vue')
  },
  {
    path: '/maintContracts',
    name: 'maintContracts',
    component: () => import(/* webpackChunkName: "maintContracts" */ '@/views/maintContracts.vue')
  },
  // {
  //   path: '/actions',
  //   name: 'actions',
  //   component: () => import(/* webpackChunkName: "clientList" */ '@/views/clientList.vue')
  // },
  {
    path: '/client-statement/:id',
    name: 'clientStatement',
    component: () => import(/* webpackChunkName: "clientStatement" */ '@/views/clientStatement.vue')
  },
  {
    path: '/view-invoice/:id',
    name: 'viewInvoice',
    component: () => import(/* webpackChunkName: "viewInvoice" */ '@/views/viewInvoice.vue')
  },
  {
    path: '/customers',
    name: 'CustomerList',
    component: () => import(/* webpackChunkName: "CustomerList" */ '../views/CustomerList.vue')
  },
  {
    path: '/reports/in-out-report',
    name: 'IncomeOutComeReport',
    component: () => import(/* webpackChunkName: "inoutreport" */ '../views/inoutreport.vue')
  },
  {
    path: '/in-out-report',
    name: 'IncomeOutComeReport2',
    component: () => import(/* webpackChunkName: "inoutreport2" */ '../views/inoutreport.vue')
  },
  {
    path: '/Licenses',
    name: 'License-Generator',
    component: () => import(/* webpackChunkName: "LicenseGen" */ '../views/LicenseGen.vue')
  },
  {
    path: '/sms',
    name: 'SMS-Generator',
    component: () => import(/* webpackChunkName: "smsTemplates" */ '../views/smsTemplates.vue')
  },
  {
    path: '/viewcustomer/:id',
    name: 'view-order', 
    component: () => import(/* webpackChunkName: "viewOrder" */ '../views/viewOrder.vue')
  },
  {
    path: '/salary-reports',
    name: 'SalaryReport',
    component: () => import(/* webpackChunkName: "salary-report" */ '../views/salary-report.vue')
  },
  {
    path: '/car-report',
    name: 'car-report',
    component: () => import(/* webpackChunkName: "car-report" */ '../views/car-report.vue')
  },
  {
    path: '/car-report-contract',
    name: 'car-report-contract',
    component: () => import(/* webpackChunkName: "car-report-contract" */ '../views/car-report-contract.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/register.vue')
  },
  // {
  //   path: '/printQuot/:id',
  //   name: 'printQuotation',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/printQuotation.vue')
  // },
  {
    path: '/Login',
    name: 'Login',
    meta: { transition: 'slide-left' },
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/view-card/:cardid',
    name: 'ViewCard',
    component: () => import(/* webpackChunkName: "ViewCard" */ '../views/ViewCard.vue')
  },
  {
    path: '/print-card/:cardid',
    name: 'printCard',
    component: () => import(/* webpackChunkName: "printCard" */ '../views/printCard.vue')
  },
  {
    path: '/signcard/:id',
    name: 'signCustomer',
    component: () => import(/* webpackChunkName: "signCustomer" */ '../views/signCustomer.vue')
  },
  {
    path: '/emps/attendees',
    name: 'employeeAttendees',
    component: () => import(/* webpackChunkName: "employeeAttendees" */ '../views/employeeAttendees.vue')
  },
  {
    path: '/print-invoice/:cardid',
    name: 'printInvoice',
    component: () => import(/* webpackChunkName: "printInvoice" */ '../views/printInvoice.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "Logout" */ '../views/Logout.vue')
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import(/* webpackChunkName: "reports" */ '../views/reports.vue')
  },
  {
    path: '/reports/cards-reports',
    name: 'cards-reports',
    component: () => import(/* webpackChunkName: "cards-reports" */ '../views/cards-reports.vue')
  },
  {
    path: '/reports/percent-reports',
    name: 'percent-reports',
    component: () => import(/* webpackChunkName: "percent-reports" */ '../views/percent-reports.vue')
  },
  {
    path: '/reports/sales-reports',
    name: 'sales-reports',
    component: () => import(/* webpackChunkName: "sales-reports" */ '../views/sales-reports.vue')
  },
  {
    path: '/reports/acbalance-report',
    name: 'acbalance-report',
    component: () => import(/* webpackChunkName: "acbalance-report" */ '../views/acbalance-report.vue')
  },
  {
    path: '/inventory/units',
    name: 'inventory-units',
    component: () => import(/* webpackChunkName: "inventory-units" */ '../views/inventory-units.vue')
  },
  {
    path: '/reports/bnl-report',
    name: 'bnl-report',
    component: () => import(/* webpackChunkName: "bnl-report" */ '../views/bnl-report.vue')
  },
  {
    path: '/reports/return-reports',
    name: 'return-reports',
    component: () => import(/* webpackChunkName: "return-reports" */ '../views/return-reports.vue')
  },
  {
    path: '/reports/vat-reports',
    name: 'vat-reports',
    component: () => import(/* webpackChunkName: "vat-reports" */ '../views/vat-reports.vue')
  },
  {
    path: '/vat-reports',
    name: 'vat-reports2',
    component: () => import(/* webpackChunkName: "vat-reports2" */ '../views/vat-reports.vue')
  },
  {
    path: '/reports/income-report',
    name: 'income-report',
    component: () => import(/* webpackChunkName: "inoutreport" */ '../views/inoutreport.vue')
  },
  {
    path: '/income-report',
    name: 'income-report2',
    component: () => import(/* webpackChunkName: "inoutreport2" */ '../views/inoutreport.vue')
  },
  {
    path: '/reports/statment-report',
    name: 'statment-report',
    component: () => import(/* webpackChunkName: "statment-report" */  '../views/statment-report.vue')
  },
  {
    path: '/reports/balance-report',
    name: 'balance-report',
    component: () => import(/* webpackChunkName: "balance-report" */ '../views/balance-report.vue')
  },
  {
    path: '/reports/daily-reports',
    name: 'daily-reports',
    component: () => import(/* webpackChunkName: "daily-reports" */ '../views/daily-reports.vue')
  },
  {
    path: '/finance',
    name: 'Finance',
    component: () => import(/* webpackChunkName: "Finance" */ '../views/Finance.vue')
  },
  {
    path: '/reports/salary-report',
    name: 'salary-report',
    component: () => import(/* webpackChunkName: "ssalary-report" */  '../views/ssalary-report.vue')
  },
  {
    path: '/settings/expensesTypes',
    name: 'expenses-types',
    component: () => import(/* webpackChunkName: "settings-expenses-types" */ '../views/settings-expenses-types.vue')
  },
  {
    path: '/reports/expenses-types',
    name: 'expenses-types-report',
    component: () => import(/* webpackChunkName: "expenses-types" */ '../views/expenses-types.vue')
  },
  {
    path: '/reports/workType',
    name: 'expenses-types-report',
    component: () => import(/* webpackChunkName: "work-type-report" */ '../views/work-type-reports.vue')
  },
  {
    path: '/reports/percent-report',
    name: 'percent-report',
    component: () => import(/* webpackChunkName: "percent-report" */ '../views/percent-report.vue')
  },
  {
    path: '/settings/employees',
    name: 'employees-settings',
    component: () => import(/* webpackChunkName: "semployees" */ '../views/semployees.vue')
  },
  {
    path: '/finance/inreceipt',
    name: 'InReceipt',
    component: () => import(/* webpackChunkName: "InReceipt" */ '../views/InReceipt.vue')
  },
  {
    path: '/finance/outreceipt',
    name: 'OutReceipt',
    component: () => import(/* webpackChunkName: "OutReceipt" */ '../views/OutReceipt.vue')
  },
  {
    path: '/finance/jvs',
    name: 'Jornal-Voucher',
    component: () => import(/* webpackChunkName: "JournalVoucher" */ '../views/JournalVoucher.vue')
  },
  {
    path: '/finance/fient',
    name: 'fJornal-Voucher',
    component: () => import(/* webpackChunkName: "fJournalVoucher" */ '../views/fJournalVoucher.vue')
  },
  {
    path: '/inventory/vendors',
    name: 'vendors',
    component: () => import(/* webpackChunkName: "vendors" */ '../views/vendors.vue')
  },
  {
    path: '/inventory/purchase',
    name: 'Purchase',
    component: () => import(/* webpackChunkName: "Purchase" */ '../views/Purchase.vue')
  },
  {
    path: '/coa-reports',
    name: 'coa-reports',
    component: () => import(/* webpackChunkName: "coa-reports" */ '../views/coa-reports.vue')
  },
  {
    path: '/coa-reports-totals',
    name: 'coa-reports-totals',
    component: () => import(/* webpackChunkName: "coa-repocoa-reports-totalsrts" */ '../views/coa-reports-totals.vue')
  },
  {
    path: '/percentage-report',
    name: 'percentage-report',
    component: () => import(/* webpackChunkName: "percentage-report" */ '../views/percentage-report.vue')
  },
  {
    path: '/start-Inventory',
    name: 'inventory-start',
    component: () => import(/* webpackChunkName: "inventory-start" */ '../views/inventory-start.vue')
  },
  {
    path: '/inventory-reports',
    name: 'inventory-reports',
    component: () => import(/* webpackChunkName: "inventory-reports" */ '../views/inventory-reports.vue')
  },
  {
    path: '/stores',
    name: 'Stores',
    component: () => import(/* webpackChunkName: "inventory-stors" */ '../views/inventory-stors.vue')
  },
  {
    path: '/stores-transfer',
    name: 'stores-transfer',
    component: () => import(/* webpackChunkName: "inventory-transfer" */ '../views/inventory-transfer.vue')
  },
  {
    path: '/purchase',
    name: 'PurchaseExpense',
    component: () => import(/* webpackChunkName: "PurcPurchase-Expensehase" */ '../views/Purchase-Expense.vue')
  },
  {
    path: '/purchaseReturn',
    name: 'purchaseReturn',
    component: () => import(/* webpackChunkName: "purchaseReturn" */ '../views/purchaseReturn.vue')
  },
  {
    path: '/purchase-order',
    name: 'purchaseOrder',
    component: () => import(/* webpackChunkName: "purchaseOrder" */ '../views/purchaseOrder.vue')
  },
  {
    path: '/purchase-report',
    name: 'PurchaseReport',
    component: () => import(/* webpackChunkName: "PurchaseReport" */ '../views/PurchaseReport.vue')
  },
  {
    path: '/SAMPOS',
    name: 'POS',
    component: () => import(/* webpackChunkName: "POS" */ '../views/POS.vue')
  },
  {
    path: '/POS-RETAIL',
    name: 'POS-RETAIL',
    component: () => import(/* webpackChunkName: "POS-RETAIL" */ '../views/POS-RETAIL.vue')
  },
  {
    path: '/marketing',
    name: 'MARKETING',
    component: () => import(/* webpackChunkName: "marketing" */ '../views/marketing.vue')
  },
  
  {
    path: '/settings/system-settings',
    name: 'SYSTEM SETTINGS',
    component: () => import(/* webpackChunkName: "system-settings" */ '../views/system-settings.vue')
  },
  {
    path: '/settings/printing',
    name: 'Printing SETTINGS',
    component: () => import(/* webpackChunkName: "printingSettings" */ '../views/printingSettings.vue')
  },
  {
    path: '/emps/users-settings',
    name: 'USERS SETTINGS',
    component: () => import(/* webpackChunkName: "users-settingse" */ '../views/users-settings.vue')
  },
  {
    path: '/settings/users-settings',
    name: 'USERSSETTINGS',
    component: () => import(/* webpackChunkName: "users-settings" */ '../views/users-settings.vue')
  },
  {
    path: '/settings/permissions-settings',
    name: 'PERMISSIONS SETTINGS',
    component: () => import(/* webpackChunkName: "permissions-settings" */ '../views/permissions-settings.vue')
  },
  {
    path: '/emps/workorders',
    name: 'WorkOrders',
    component: () => import(/* webpackChunkName: "permissions-settings" */ '../views/work-orders.vue')
  },
  {
    path: '/emps/salaries',
    name: 'SALARIES',
    component: () => import(/* webpackChunkName: "salaries" */ '../views/salaries.vue')
  },
  {
    path: '/emps/gozi-report',
    name: 'gozi-report',
    component: () => import(/* webpackChunkName: "gozi-report" */ '../views/gozi-report.vue')
  },
  {
    path: '/expenses/recurring',
    name: 'expenses',
    component: () => import(/* webpackChunkName: "expenses" */ '../views/expenses.vue')
  },
  {
    path: '/expenses/management',
    name: 'management',
    component: () => import(/* webpackChunkName: "exp-management" */ '../views/exp-management.vue')
  },
  {
    path: '/inventory/products',
    name: 'Products',
    component: () => import(/* webpackChunkName: "settings-products" */ '@/views/settings-products.vue')
  },
  {
    path: '/inventory/products-cats',
    name: 'products-cats',
    component: () => import(/* webpackChunkName: "products-cats" */ '@/views/products-cats.vue')
  },
  {
    path: '/inventory/best-seller',
    name: 'bestseller',
    component: () => import(/* webpackChunkName: "best-seller" */ '@/views/best-seller.vue')
  },
  {
    path: '/inventory/lowest-selling',
    name: 'lowestselling',
    component: () => import(/* webpackChunkName: "lowest-selling" */ '@/views/lowest-selling.vue')
  },
  {
    path: '/inventory/finished',
    name: 'finished',
    component: () => import(/* webpackChunkName: "finished" */ '@/views/finished.vue')
  },
  {
    path: '/view-purchase/:id',
    name: 'view-purchase',
    component: () => import(/* webpackChunkName: "viewPurchase" */ '@/views/viewPurchase.vue')
  },
  {
    path: '/emp/list',
    name: 'employee-list',
    component: () => import(/* webpackChunkName: "employees" */ '@/views/employees.vue')
  },
  {
    path: '/emps/view/:id',
    name: 'employeeview',
    component: () => import(/* webpackChunkName: "view-employees" */ '@/views/view-employees.vue')
  },
  {
    path: '/emps/salary-table',
    name: 'salarytable',
    component: () => import(/* webpackChunkName: "salary-table" */ '@/views/salary-table.vue')
  },
  {
    path: '/updateuser/:id',
    name: 'employee-view',
    component: () => import(/* webpackChunkName: "update-user" */ '@/views/update-user.vue')
  },
  {
    path: '/expenses/summary',
    name: 'expenses-module',
    component: () => import(/* webpackChunkName: "expenses-module" */ '@/views/expenses-module.vue')
  },
  {
    path: '/expenses/general',
    name: 'expenses-module1',
    component: () => import(/* webpackChunkName: "expenses-general" */  '@/views/expenses-general.vue')
  },
  {
    path: '/general-expenses',
    name: 'expenses-module1-purchase',
    component: () => import(/* webpackChunkName: "expenses-general-purchase" */ '@/views/expenses-general-purchase.vue')
  },
  {
    path: '/finance/coa',
    name: 'chart-of-account',
    component: () => import(/* webpackChunkName: "chart-of-account" */ '@/views/chart-of-account.vue')
  },
  {
    path: '/finance/definition',
    name: 'finance-definitions',
    component: () => import(/* webpackChunkName: "finance-definitions" */ '@/views/finance-definitions.vue')
  },
  {
    path: '/finance/paytypes',
    name: 'finance-paytypes',
    component: () => import(/* webpackChunkName: "finance-paytypes" */ '@/views/finance-paytypes.vue')
  },
  {
    path: '/finance/fixed-assets',
    name: 'fixed-assets',
    component: () => import(/* webpackChunkName: "fixed-assets" */ '@/views/fixed-assets.vue')
  },
  {
    path: '/inventory/itemtrans',
    name: 'itemtrans',
    component: () => import(/* webpackChunkName: "itemtrans" */ '@/views/itemtrans.vue')
  },
  {
    path: '/vendors/vendor-index/',
    name: 'vendor control panel',
    component: () => import(/* webpackChunkName: "vendor-index" */ '@/views/vendor-index.vue')
  },
  {
    path: '/vendors/products/',
    name: 'vendor products',
    component: () => import(/* webpackChunkName: "vendors-products" */ '@/views/vendors-products.vue')
  },
  {
    path: '/vendors/payment-requests/',
    name: 'vendor payment-requests',
    component: () => import(/* webpackChunkName: "vendor-payments" */ '@/views/vendor-payments.vue')
  },
  {
    path: '/vendors/requests/',
    name: 'vendor send-requests',
    component: () => import(/* webpackChunkName: "vendor-requests2" */ '@/views/vendor-requests.vue')
  },
  {
    path: '/vendor-sents/',
    name: 'vendor-sents',
    component: () => import(/* webpackChunkName: "vendor-sents" */ '@/views/vendor-sents.vue')
  },
  {
    path: '/vendor-prequests/',
    name: 'vendor-psent',
    component: () => import(/* webpackChunkName: "vendor-sents" */ '@/views/vendor-psent.vue')
  },
  {
    path: '/billClients',
    name: 'billClients',
    component: () => import(/* webpackChunkName: "showClients" */ '@/views/showClients.vue')
  },
  {
    path: '/customer-suppport',
    name: 'CustomerSupport',
    component: () => import(/* webpackChunkName: "customerSupport" */ '@/views/customerSupport.vue')
  },
  {
    path: '/emps/custody',
    name: 'EmpCustody',
    component: () => import(/* webpackChunkName: "EmpCustody" */ '@/views/EmpCustody.vue')
  },
  {
    path: '/pos/invoiceList',
    name: 'POS-InvoiceList',
    component: () => import(/* webpackChunkName: "POS-invoiceList" */ '@/views/POS-invoiceList.vue')
  },
  {
    path: '/pos/employees',
    name: 'POS-Employees',
    component: () => import(/* webpackChunkName: "POS-employees" */ '@/views/POS-employees.vue')
  },
  {
    path: '/pos/Purchase',
    name: 'pos-Purchase',
    component: () => import(/* webpackChunkName: "pos-Purchase" */ '@/views/pos-Purchase.vue')
  },
  {
    path: '/pos/vendors',
    name: 'pos-vendors',
    component: () => import(/* webpackChunkName: "pos-vendors" */ '@/views/pos-vendors.vue')
  },
  {
    path: '/pos/Expenses',
    name: 'Expenses',
    component: () => import(/* webpackChunkName: "pos-Purchase" */ '@/views/pos-Expenses.vue')
  },
  {
    path: '/pos/report/daily',
    name: 'daily report',
    component: () => import(/* webpackChunkName: "pos-DailyReport" */ '@/views/pos-DailyReport.vue')
  },
  {
    path: '/pos/report/vat-report',
    name: 'vat report',
    component: () => import(/* webpackChunkName: "pos-VatReport" */ '@/views/pos-VatReport.vue')
  },
  {
    path: '/pos/report/items-max',
    name: 'pos-items-max',
    component: () => import(/* webpackChunkName: "pos-MostSales" */ '@/views/pos-MostSales.vue')
  },
  {
    path: '/report/items-max',
    name: 'items-max',
    component: () => import(/* webpackChunkName: "pos-MostSales" */ '@/views/MostSales.vue')
  },
  {
    path: '/report/items-less',
    name: 'items-less',
    component: () => import(/* webpackChunkName: "pos-MostSales" */ '@/views/LessSales.vue')
  },
  {
    path: '/pos/report/items-less',
    name: 'pos-items-less',
    component: () => import(/* webpackChunkName: "pos-LessSales" */ '@/views/pos-LessSales.vue')
  },
  {
    path: '/pos/report/items-zero',
    name: 'items-zero',
    component: () => import(/* webpackChunkName: "pos-ZeroInventory" */ '@/views/pos-ZeroInventory.vue')
  },
  {
    path: '/pos/clientList',
    name: 'clientList',
    component: () => import(/* webpackChunkName: "pos-clientList" */ '@/views/pos-clientList.vue')
  },
  {
    path: '/booking',
    name: 'Booking',
    component: () => import(/* webpackChunkName: "BookingModule" */ '@/views/BookingModule.vue')
  },
  {
    path: '/maint-contract',
    name: 'MaintContract',
    component: () => import(/* webpackChunkName: "MaintContract" */ '@/views/MaintContract.vue')
  },
  {
    path: '/external-maint',
    name: 'ExternalMent',
    component: () => import(/* webpackChunkName: "ExternalMent" */ '@/views/ExternalMent.vue')
  },
  {
    path: '/periodic-maint',
    name: 'ExternalMent',
    component: () => import(/* webpackChunkName: "periodicMaintenance" */ '@/views/periodicMaintenance.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404 page',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue')
  },
  {
    path: '/settings',
    name: 'SETTINGS',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/settings.vue')
  },
  /** POS - Routes */
  
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
